<template>
    <div class="signin">
      <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
        <div class="title">
          <span class="text">{{ $t('home.navTitles.signin') }}</span>
        </div>
      </div>
      <!-- <div v-if="!showComponent" class="content">
        <div v-if="!signinData.signin_switch_on">签到功能未启用</div>
        <div v-else>
          <a-row :gutter="2" class="nav-bars">
            <a-col :span="index==6?16:8" v-for="(item,index) in signinData.signin_gift" :key="index">
                <div class=" nav-bar" :class="weekSignCount > index?'on':''">
                    <div class="profit">
                      <div> {{ item[0] }} {{ $t('common.currency') }}</div>
                      <div> {{ item[1] }}GFD</div>
                    </div>
                    <div class="title">
                        连签{{ index+1 }}天
                    </div>
                </div>
            </a-col>
            <a-col :span="8">
              <div class="nav-bar signin-btn" @click="signin">
                SIGNIN
              </div>
            </a-col>
          </a-row>
          <div>本周已连续签到{{ weekSignCount }}天</div>
        </div>
      </div> -->
      <Signin-Component />
    </div>
</template>
<script>
import SigninComponent from '../components/Signin.vue';


export default {
  name: 'Signin',
  data(){
    return {
      showComponent: true,
      // weekSignCount: 0,
      // signinData: {}
    }
  },
  components:{
    SigninComponent
  },
  mounted(){
    // this.getData();
    // if(this.$store.state.token){
    //   this.getWeekSignCount();
    // }
  },
  methods:{
    // getData(){
    //   let _this = this
    //   this.$http.call(this.$http.api.signinfo).then(function(res){
    //       // console.log(res.data)
    //       if(res.data.code == '200'){
    //         _this.signinData = res.data.data;
    //       }
    //   },function(res){
    //       console.log('something goes wrong...');
    //       console.log(res);
    //   });
    // },
    // getWeekSignCount(){
    //   let _this = this
    //   this.$http.call(this.$http.api.signinCount).then(function(res){
    //       // console.log(res.data)
    //       if(res.data.code == '200'){
    //         _this.weekSignCount = res.data.data;
    //       }
    //   },function(res){
    //       console.log('something goes wrong...');
    //       console.log(res);
    //   });
    // },
    // signin(){
    //   if(!this.$store.state.token){
    //     this.$router.push({name:'login'})
    //     return;
    //   }
    //   let _this = this
    //   this.$http.call(this.$http.api.signin).then(function(res){
    //       console.log(res.data)
    //       if(res.data.code == '200'){
    //         _this.weekSignCount = res.data.data.times;
    //         _this.$message.success(res.data.msg);
    //       }else{
    //         _this.$message.error(res.data.data);
    //         if(res.data.code == '99'){
    //           setTimeout(()=>{
    //             _this.$router.push({name: 'tasks'});
    //           },2000)
    //         }
    //       }
    //   },function(res){
    //       console.log('something goes wrong...');
    //       console.log(res);
    //   });
    // }
  }
}
</script>
<style lang="less">
.signin{
  padding: 30px 50px;
  color: #fff;
  height: 100%;
  overflow-y: scroll;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .back {
        position: absolute;
        left: 0;
        font-size: 20PX;
        // color: #a8a8a8;
    }
    .title {
        font-weight: bold;
        font-size: 16PX;
        // color: @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-img {
            height: 100px;
        }
    }
  }
  // .nav-bars{
  //       display: flex;
  //       flex-wrap: wrap;
  //       margin: 24px -12px;
  //       .nav-bar{
  //           background: #dadada;
  //           color: @primary-color;
  //           padding: 20px;
  //           margin: 1px auto;
  //           // width: 100%;
  //           display: flex;
  //           flex-direction: column;
  //           justify-content: center;
  //           align-items: center;
  //           .title {
  //             color: @primary-color;
  //             font-weight: bold;
  //             padding-top: 7px;
  //           }
  //       }.on{
  //         background: #f1f1f1;
  //       }.signin-btn{
  //         background: @primary-color;
  //         color: #fff;
  //         font-weight: bold;
  //         height: 100%;
  //       }
  //   }
}

</style>
