<template>
  <div class="sign-in">
    <div v-if="!signinData.signin_switch_on">签到功能未启用</div>
    <div v-else class="panel">
      <div class="panel-title">
        <div class="title">本周已连续签到 <span class="day">{{ weekSignCount }}</span> 天</div>
      </div>


      <div class="nav-bars">
          <div v-for="(item,index) in signinData.signin_gift" :key="index" class="nav-bar" :class="weekSignCount > index?'on':'' + index==6?'last':''">
            <div class="title">
               第{{ index+1 }}天
            </div>
            <div v-if="weekSignCount <= index" class="profit">
              <div class="profit-item">
                <div> <img class="coin" src="@/assets/imgs/signin/coin_bt.png" alt=""> </div>
                <div>x{{ item[0] }} </div>
                <div class="unit">{{ $t('common.currency') }}</div>
              </div>
              <div class="profit-item"> 
                <div> <img class="coin" src="@/assets/imgs/signin/coin_gfd.png" alt=""> </div>
                <div>x{{ item[1] }}</div>
                <div class="unit">GFD</div>
              </div>
            </div>
              
          </div>
        <div class="nav-bar signin-btn" @click="signin">
          SIGNIN
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'SigninComponent',
  data () {
    return {
      weekSignCount: 0,
      signinData: {}
    }
  },
  mounted () {
    this.getData();
    if(this.$store.state.token){
      this.getWeekSignCount();
    }
  },
  methods: {
    getData(){
      let _this = this
      this.$http.call(this.$http.api.signinfo).then(function(res){
          // console.log(res.data)
          if(res.data.code == '200'){
            _this.signinData = res.data.data;
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    getWeekSignCount(){
      let _this = this
      this.$http.call(this.$http.api.signinCount).then(function(res){
          // console.log(res.data)
          if(res.data.code == '200'){
            _this.weekSignCount = res.data.data;
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    signin(){
      if(!this.$store.state.token){
        this.$router.push({name:'login'})
        return;
      }
      let _this = this
      this.$http.call(this.$http.api.signin).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.weekSignCount = res.data.data.times;
            _this.$message.success(res.data.msg);
          }else{
            _this.$message.error(res.data.data);
            if(res.data.code == '99'){
              setTimeout(()=>{
                _this.$router.push({name: 'tasks'});
              },2000)
            }
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    }
  }
}
</script>

<style lang="less" scoped>
.sign-in {
  margin-top: 50px;
  .panel {
    max-width: 988px;
    .panel-title {
      width: 100%;
      height: 434px;
      margin: 0 auto;
      background: url('~@/assets/imgs/signin/title_bg.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      // align-items: flex-end;
      position: relative;
      .title {
        position: absolute;
        bottom: 50px;
        font-size: 50px;
        font-weight: bold;
        color: #777;
        .day {
          color: #a9261c; font-size: 60px;
        }
      }
    }
    .nav-bars{
      background: #333333aa;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom-left-radius: 36px;
      border-bottom-right-radius: 36px;
      padding: 75px 24px;
      // margin: 24px -12px;
      .nav-bar{
        width: 281px; height: 341px;
        margin: 10px;
        background-image: url('~@/assets/imgs/signin/item_bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        color: @primary-color;
        // padding: 20px;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        .title {
          color: @primary-color;
          font-weight: bold;
          padding-top: 7px;
          position: absolute;
          top: 0;
        }
        .profit {
          padding-bottom: 15px;
          color: #fbe7bb;
          display: flex;
          justify-content: space-around;
          .profit-item {
            padding: 15px;
            font-weight: bold;
            .coin {
              height: 80px;
              padding-bottom: 10px;
            }
            .unit {
              font-weight: normal;
              font-size: 10PX;
            }
          }
        }
      }.on{
        background-image: url('~@/assets/imgs/signin/item_done_bg.png');

      }.last{
        width: 583px;
        background-image: url('~@/assets/imgs/signin/item_last_bg.png');

      }.signin-btn{
        height: 341px;
        background: @primary-color;
        color: #fbe7bb;
        font-weight: bold;
        justify-content: center;
      }
    }
  }
  
}
</style>
